@tailwind base;
@tailwind components;
@tailwind utilities;

.zoom-in-zoom-out {
    animation: zoom-in-zoom-out 12s ease-out infinite;
    height: 100%;
    width: 100%;
    transform-origin: 0% 0%; 
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1.2, 1.2);
    }
    50% {
      transform: scale(1.1, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }


  .animate-bottom{
    position:relative;
    animation:animatebottom 12s infinite;
    
  }
  
  @keyframes animatebottom {
    0%{
      bottom:-300px;
      opacity:0
    } 
    5%{
      bottom:0;
      opacity:1
    }
    50%{
      bottom:0;
      opacity:1
    }
    
    55%{
      bottom:-300px;
      opacity:0
    }
    60%{
      bottom:0;
      opacity:1
    }
    100%{
      bottom:0;
      opacity:1
    }
  }
  @layer base {
    [type="text"],
    [type="email"],
    [type="url"],
    [type="password"],
    [type="number"],
    [type="date"],
    [type="datetime-local"],
    [type="month"],
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="week"],
    [multiple],
    textarea,
    select {
      @apply w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500;
    }
    [type="checkbox"],
    [type="radio"] {
      @apply border-gray-300 rounded text-indigo-600 focus:ring-indigo-500;
    }
  }